<template>
  <div class="pageContol formCom">
    <div class="framePage">
      <div class="framePage-title">
        <span>
          <em>当前位置：</em>
          <a href="javascript:;" @click="$router.back()">我的资源</a>
          <i>></i>
          <a href="javascript:;" @click="$router.back()">我的课程/课件</a>
          <i>></i>
          <a href="javascript:;" @click="init" class="cur-a"
            >{{ courseId ? "修改" : "创建" }}课程</a
          >
        </span>
      </div>
      <div class="framePage-body">
        <div class="framePage-scroll">
          <div class="ovy-a">
            <h3 style="padding-bottom: 20px">课程基本信息</h3>
            <el-form
              ref="ruleForm"
              :model="ruleForm"
              :rules="rules"
              label-width="10rem"
              class
            >
              <el-form-item
                label="课程名称："
                prop="courseName"
                class="form-item"
              >
                <el-input
                  v-model="ruleForm.courseName"
                  maxlength="50"
                  :show-word-limit="true"
                  :disabled="ruleForm.id ? true : false"
                  placeholder="请输入课程名称"
                  size="small"
                />
              </el-form-item>
               <el-form-item
                label="培训类型："
                prop="trainTypeId"
                class="form-item"
              >
                <el-cascader
                  v-model="ruleForm.trainTypeId"
                  :options="TrainTypeList"
                  filterable
                  :show-all-levels="false"
                  :props="{
                    emitPath: false,
                    value: 'id',
                    label: 'label',
                    children: 'children',
                    checkStrictly: true,
                  }"
                  size="small"
                ></el-cascader>
              </el-form-item>
              <el-form-item
                label="课程图片："
                prop="thumbnail"
                :class="[ruleForm.id ? 'item-disabled' : '']"
              >
                <el-upload
                  :on-change="handlebusinessLicense2"
                  :before-upload="$beforeAvatarUpload"
                  :http-request="$requestMine"
                  :show-file-list="false"
                  class="img-el-upload"
                  action
                  accept="image/png, image/gif, image/jpg, image/jpeg"
                >
                  <el-image
                    :src="ruleForm.thumbnail || require('@/assets/develop.png')"
                    fit="contain"
                    class="imgCenter"
                  ></el-image>
                </el-upload>
              </el-form-item>
              <el-form-item label=" " class="form-item form-item-hint">
                <p>建议上传尺寸640*365。</p>
              </el-form-item>
              <el-form-item label="课程详情：" prop="context" class="form-item">
                <div
                  ref="editor"
                  class="editor"
                  style="width: 100%; max-width: 1200px"
                />
              </el-form-item>
             
            </el-form>
            <h3 style="padding-bottom: 20px">课程章节</h3>
            <!-- <div style="display: flex; color: red" v-if="courseId">
              <p style="padding: 10px">
                课程总课时:{{ ruleForm.kpointTotalNum }}
              </p>
              <p style="padding: 10px">
                总学时:{{ ruleForm.kpointLessonNum }}学时
              </p>
              <p style="padding: 10px">
                视频总时长:{{ getTime1(ruleForm.duration) }}
              </p>
              <p style="padding: 10px" v-if="length1.length > 0">
                未识别时长课件数量:{{ length1.length }}
              </p>
            </div> -->

            <div style="display: flex; width: 100%" class>
              <div
                style="
                  width: 63%;
                  border-right: 1px dashed #adadad;
                  margin-right: 25px;
                "
              >
                <div class="btnbox">
                  <el-button
                    size="mini"
                    class="bgc-bv"
                    style="margin-right: 6px; padding: 10px"
                    round
                    v-if="!data.length"
                    @click="doChooseStyle"
                    >选择目录样式
                  </el-button>
                  <el-button
                    class="bgc-bv"
                    v-show="!data.length"
                    style="margin-right: 16px; padding: 10px"
                    size="mini"
                    round
                    @click="doTreeAppendone"
                    :disabled="disabled"
                    >新增一级项
                  </el-button>
                  <!-- <el-button class="bgc-bv" round @click="dialogVisible = false">快捷添加目录</el-button> 临时隐藏 -->
                </div>
                <section class="chapterl-trees createdCourse">
                  <el-tree
                    :data="data"
                    node-key="id"
                    default-expand-all
                    :draggable="draggable && !disabled"
                    :allow-drop="allowDrop"
                    :allow-drag="allowDrag"
                    v-if="data.length"
                    @node-drop="handleDrop"
                  >
                    <div class="custom-tree-node" slot-scope="{ node, data }">
                      <p class="flexac" v-if="data.level === 1">
                        <span style="width: 44px; text-align: left">
                          <span class="level" style="margin-right: 10px">
                            <!-- 一级 -->
                            {{ data.chapterNum }}
                          </span>
                        </span>

                        <el-input
                          ref="input"
                          size="mini"
                          style="width: 130px"
                          v-if="data.isEdit == 1"
                          @blur="submitEdit(node, data)"
                          @keyup.enter.native="handleEnter($event)"
                          v-model="treeLable"
                        />
                        <span
                          :title="node.label"
                          class="ellipsis"
                          style="margin-right: 20px"
                          v-else
                          >{{ node.label }}
                        </span>
                        <span class="trees-btns">
                          <el-button
                            size="mini"
                            style="padding: 7px 10px"
                            :disabled="disabled && !data.isEdit"
                            @click.stop="() => doTreeDel(node, data)"
                            >删除</el-button
                          >
                          <el-button
                            size="mini"
                            style="padding: 7px 10px"
                            @click.stop="() => doTreeEdit(node, data)"
                            :disabled="disabled"
                            >重命名</el-button
                          >
                          <el-button
                            :disabled="disabled"
                            size="mini"
                            style="padding: 7px 10px"
                            @click.stop="() => doTreeAppend(node, data)"
                            >新增二级项</el-button
                          >
                          <el-button
                            :disabled="disabled"
                            size="mini"
                            style="padding: 7px 10px"
                            @click.stop="() => doAddCourseMore(node, data)"
                            >批量添加课件</el-button
                          >
                        </span>
                      </p>
                      <template v-else-if="data.level === 2">
                        <draggable
                          class="tree-drag-group"
                          :class="{
                            'bgc-height':
                              data.list.length && data.list[0].kpointDeleted,
                          }"
                          :value="data.list"
                          :group="{ name: 'people', put: true, pull: false }"
                          :sort="false"
                          @change="
                            (evt) => {
                              nodeChange(evt, data);
                            }
                          "
                          handle=".good"
                          :prevent-on-filter="true"
                        >
                          <span style="width: 60px; text-align: left">
                            <span
                              class="level"
                              v-if="styleType == '1'"
                              style="margin-right: 10px"
                            >
                              <!-- 二级 -->
                              {{ data.chapterNum }}
                            </span>
                            <span
                              class="level"
                              v-else
                              style="margin-right: 10px"
                            >
                              <!-- 一级 -->
                              {{ data.chapterNum }}
                            </span>
                          </span>
                          <span
                            v-if="data.list.length > 0"
                            style="
                              display: flex;
                              flex-direction: row;
                              align-items: center;
                            "
                          >
                            <i
                              class="courseIcon"
                              v-if="
                                data.list[0].kpointVideoId ||
                                data.list[0].kpointVideoUrl
                              "
                            ></i>
                            <i
                              class="paperIcon"
                              v-else-if="data.list[0].paperId"
                            ></i>
                          </span>
                          <span
                            v-else
                            style="
                              display: flex;
                              flex-direction: row;
                              align-items: center;
                            "
                          >
                            <i class="nullIcon"></i>
                          </span>
                          <el-input
                            ref="input"
                            size="mini"
                            style="width: 120px"
                            v-if="data.isEdit == 1"
                            @blur="submitEdit(node, data)"
                            @keyup.enter.native="handleEnter($event)"
                            v-model="treeLable"
                          />
                          <span
                            :title="data.label"
                            class="ellipsis"
                            style="margin-right: 3px; width: 203px"
                            v-else
                            >{{ data.label }}</span
                          >
                          <span style="color: red" v-if="courseId"
                            >{{ data.kpointLessonNum }}学时</span
                          >
                          <span style="color: red; padding-left: 10px" v-if="courseId"
                            >视频时长:{{ getTime1(data.kpointDuration) }}</span
                          >

                          <span class="trees-btns" :canDarg="data.list.length">
                            <el-button
                              style="padding: 7px 10px"
                              size="mini"
                              :disabled="disabled && !data.isEdit"
                              @click.stop="() => doTreeDel(node, data)"
                              >删除</el-button
                            >
                            <el-button
                              size="mini"
                              style="padding: 7px 10px"
                              @click.stop="() => doTreeEdit(node, data)"
                              :disabled="disabled"
                              >重命名</el-button
                            >
                            <el-button
                              size="mini"
                              style="padding: 7px 10px"
                              @click.stop="() => doAddCourse(node, data)"
                              >{{
                                data.list && data.list.length
                                  ? "更改内容"
                                  : "添加内容"
                              }}</el-button
                            >
                            <template v-if="data.list.length">
                              <span
                                class="draggable-item handle"
                                v-for="element in data.list"
                                :key="element.name"
                              >
                                <el-button
                                  class="showbtn bgc-bv"
                                  size="mini"
                                  style="padding: 7px 10px"
                                  v-if="element.progress === 100"
                                  @click.stop="() => showVideo(data)"
                                  >预览</el-button
                                >
                                <template v-else-if="element.progress">
                                  <el-progress
                                    :percentage="element.progress"
                                  ></el-progress>
                                </template>
                                <span v-else>等待上传</span>
                              </span>
                              <span
                                v-if="data.list[0].kpointDeleted"
                                style="color: #f46173; margin-left: 30px"
                                >课件已被删除</span
                              >
                            </template>
                          </span>
                        </draggable>
                      </template>
                      <!-- 试卷 -->
                      <!-- <template v-else>
                        <p class="flexac">
                          <i
                            class="el-icon-tickets"
                            style="margin-right: 8px"
                          ></i>
                          <el-input
                            ref="input"
                            size="mini"
                            v-if="data.isEdit == 1"
                            @blur="submitEdit(node, data)"
                            @keyup.enter.native="handleEnter($event)"
                            v-model="treeLable"
                          />
                          <span :title="node.label" class="ellipsis" v-else>{{
                            node.label
                          }}</span>
                          <span class="trees-btns">
                            <el-button
                              size="mini"
                              style="padding: 7px 10px"
                              :disabled="disabled && !data.isEdit"
                              @click.stop="() => doTreeDel(node, data)"
                              >删除</el-button
                            >
                            <el-button
                              size="mini"
                              style="padding: 7px 10px"
                              @click.stop="() => doTreeEdit(node, data)"
                              :disabled="disabled"
                              >重命名</el-button
                            >
                          </span>
                        </p>
                      </template> -->
                    </div>
                  </el-tree>
                  <div v-else>
                    <p style="padding: 50px; font-size: 14px" v-if="textshow">
                      您还没有目录哦，首先要创建一个目录，你可以
                      <span
                        style="color: #58b5dd; font-size: 14px; cursor: pointer"
                        @click="doChooseStyle"
                        >选择目录样式</span
                      >
                    </p>
                    <p style="padding: 50px; font-size: 14px" v-else>
                      您可以批量上传课件或逐个上传课件
                    </p>
                  </div>
                </section>
                <div
                  class="df"
                  style="margin-right: 16px; padding: 10px; margin-bottom: 20px"
                >
                  <el-button
                    v-show="data.length"
                    class="bgc-bv"
                    size="mini"
                    round
                    @click="doTreeAppendone"
                    :disabled="disabled"
                    >新增一级项
                  </el-button>
                  <el-button
                    :disabled="disabled"
                    v-show="styleType == 2"
                    class="bgc-bv"
                    size="mini"
                    round
                    style="padding: 7px 10px"
                    @click.stop="() => doAddCourseMoreStyle2()"
                    >批量添加课件
                  </el-button>
                </div>
              </div>
              <div style="flex: 1">
                <div class="flexcb" style="align-items: center">
                  <div class="df">
                    <uploadAuthArr
                      @onAddFile="onAddFile"
                      @fileUploadProgress="uploadFileProgress"
                      @fileUploadSuccess="uploadFileSuccess"
                      ref="batchUploader"
                    />

                    <el-button
                      style="margin-left: 16px; font-size: 12px; padding: 10px"
                      class="bor-bv"
                      round
                      size="mini"
                      @click="quickAssociate"
                      >快速关联
                    </el-button>
                  </div>
                </div>
                <section class="chapteru-lists">
                  <draggable
                    class="file-list-group"
                    :value="uploadFileList"
                    :group="{ name: 'people', put: false }"
                    :move="listCheckMove"
                    :sort="false"
                    filter=".uncompleted"
                    @filter="fileFilted"
                    v-if="uploadFileList.length"
                  >
                    <div
                      class="list-group-item"
                      :class="{
                        'teacher-unbind': !item.teacher,
                        uncompleted: !item.progress || item.progress < 100,
                      }"
                      v-for="item in uploadFileList"
                      :key="item.videoId"
                    >
                      <el-tooltip
                        content="拖拽关联课程章节"
                        placement="left-end"
                      >
                        <!-- <i class="el-icon-receiving"></i> -->
                        <div slot="content">
                          <i class="el-icon-back"></i>
                          <!-- <img style="width:15px;height:15px" src="../../assets/tool.png" alt=""> -->
                          <span>拖拽关联课程章节</span>
                        </div>
                        <img
                          src="@/assets/uploadcourse.png"
                          alt
                          style="width: 28px; height: 32px; margin-right: 14px"
                        />
                      </el-tooltip>

                      <div class="df flex1">
                        <div class="cl-img-box"></div>
                        <template>
                          <el-tooltip placement="left-end">
                            <div slot="content">
                              <i class="el-icon-back"></i>
                              <span>拖拽关联课程章节</span>
                            </div>
                            <div class="cl-updata-box flex1">
                              <p style="font-size: 14px">{{ item.dispName }}</p>
                              <div class="cl-subhint">
                                <el-progress
                                  :percentage="item.progress"
                                  v-if="item.progress && item.progress < 100"
                                ></el-progress>
                                <span
                                  style="
                                    font-size: 14px;
                                    color: rgba(51, 51, 51, 1);
                                    margin-right: 30px;
                                  "
                                  v-else
                                  >{{
                                    item.progress ? "上传完成" : "等待上传"
                                  }}</span
                                >
                                <span
                                  v-if="item.teacher"
                                  class="teacher-module"
                                >
                                  {{ item.teacher.name }}
                                  <em v-if="item.teacher.qualification">|</em>
                                  {{ item.teacher.qualification.name }}
                                </span>
                              </div>
                            </div>
                          </el-tooltip>
                          <div class="cl-btns">
                            <el-tooltip content="删除课件" placement="top-end">
                              <i
                                class="el-icon-error"
                                @click.stop="() => delUploadFile(item)"
                              ></i>
                            </el-tooltip>
                          </div>
                        </template>
                      </div>
                    </div>
                  </draggable>
                  <p
                    class="df"
                    style="padding: 20px; font-size: 14px"
                    v-show="!uploadFileList.length"
                  >
                    如果您的课件还未上传，可以在此处进行
                    <uploadAuthArr
                      @onAddFile="onAddFile"
                      @fileUploadProgress="uploadFileProgress"
                      @fileUploadSuccess="uploadFileSuccess"
                      ref="batchUploader"
                      text="批量上传"
                    />
                  </p>
                </section>
              </div>
            </div>
            <div class="pageForm-btns">
              <el-button @click="onCancel" class="bgc-bv">取消</el-button>
              <el-button
                class="bgc-bv"
                @click="
                  () => {
                    onSubmit('10');
                  }
                "
                >保存
              </el-button>
            </div>
          </div>
        </div>
      </div>
    </div>
    <enterpriseInternalTrainingMyCourseTable ref="enterpriseInternalTrainingMyCourseTable" @eventBus="couseBack" />
    <enterpriseInternalTrainingMyCourseTableMore ref="enterpriseInternalTrainingMyCourseTableMore" @eventBus="courseTableMoreBack" />
    <MenuStyle ref="MenuStyle" @eventBus="styleBack" />
    <el-dialog
      v-if="dialogVisible1"
      :title="videotitle"
      :visible.sync="dialogVisible1"
      width="60%"
      :before-close="handleClose"
    >
      <player_2
        :videoId="videoId"
        :videoTime="videoTime"
        :kpointSource="kpointSource"
        :kpointVideoUrl="kpointVideoUrl"
        v-if="videoType"
      />
      <player_1
        :videoId="videoId"
        :videoTime="videoTime"
        :kpointSource="kpointSource"
        :kpointVideoUrl="kpointVideoUrl"
        v-else
      />
    </el-dialog>
  </div>
</template>
<script>
import draggable from "@/utils/vuedraggable";
import UploadPic from "@/mixins/UploadPic";
import enterpriseInternalTrainingMyCourseTable from "@/views/CustomPrograms/myCourseCourseWare/enterpriseInternalTrainingMyCourseTable";
import enterpriseInternalTrainingMyCourseTableMore from "@/views/CustomPrograms/myCourseCourseWare/enterpriseInternalTrainingMyCourseTableMore";
import MenuStyle from "@/views/resourse/popup/MenuStyle"; //章节选择
import Form from "@/mixins/Form";
import E from "wangeditor";
import uploadAuthArr from "@/components/uploadAuthArr";
import player_1 from "@/components/player";
import player_2 from "@/components/playerTrysee";
import { createSwigger, distorySwigger } from "../../../utils/swigger";
let editor = {};
let updataTimer = null;
export default {
  name: "CustomPrograms/enterpriseInternalTrainingUploadMyCourse",
  components: {
    draggable,
    enterpriseInternalTrainingMyCourseTable,
    MenuStyle,
    uploadAuthArr,
    enterpriseInternalTrainingMyCourseTableMore,
    player_1,
    player_2,
  },
  mixins: [UploadPic, Form],
  data() {
    return {
      courseId: 0,
      styleType: 1,
      addBtnIsOut: false,
      textshow: true,
      props: {
        value: "id",
        label: "label",
        emitPath: false,
      },
      ruleForm: {
        resource: "",
        thumbnailId: "",
        thumbnail: "",
        context: "", // 富文本
        trainTypeId: "",
        kpointLessonNum: 0,
        kpointTotalNum: 0,
        duration: 0,
      },
      rules: {
        courseName: [
          { required: true, message: "请输入课程名称", trigger: "blur" },
        ],
        trainTypeId: [
          { required: true, message: "请选择培训类型", trigger: "change" },
        ],
        thumbnail: [
          { required: true, message: "请上传课程图片", trigger: "change" },
        ],
        context: [
          { required: true, message: "请输入课程详情", trigger: "change" },
        ],
      },
      // tree
      draggable: true,
      treeLable: "",
      data: [],
      defaultProps: {
        children: "children",
        label: "label",
      },
      // 课件列表
      uploadFileList: [],
      datas: {},
      // 上传记录
      updateRecord: [],
      dialogVisible: false,
      disabled: true,
      styleStu: false,
      deletedChapter: [],
      videoId: "", // 視頻id
      videoType: null, // true-可以看；false-试看
      dialogVisible1: false,
      videotitle: "",
      videoTime: "",
      status: 1,
      src: "",
      length1: [],
      TrainTypeList:[], //培训类型数据
    };
  },
  watch: {
    // 监听文件上传列表.每次更新都将上传成功的视频刷入缓存
    uploadFileList: {
      deep: true,
      handler(list) {
        let uploadedFiles = list.filter((file) => {
          return file.progress == 100;
        });
        localStorage.setItem("uploadedFiles", JSON.stringify(uploadedFiles));
      },
    },
  },
  created() {
    // 新增时加载缓存
    if (this.$route.query.courseId) {
      this.courseId = this.$route.query.courseId;
      this.courseOrigin = this.$route.query.courseOrigin;
    }
    let filesStr = localStorage.getItem("uploadedFiles");
    if (filesStr) {
      this.uploadFileList = JSON.parse(filesStr);
      console.log(this.uploadFileList);
    }
  },
  mounted() {
    this.editorInit();
    this.getTrainTypeArrData();
    this.init();
  },
  methods: {
     //获取培训类型数据
    getTrainTypeArrData() {
      this.$post("/sys/category/train-type/enterprise", {}).then((ret) => {
        this.TrainTypeList = ret.data;
      });
    },
    init() {
      if (this.courseId) {
        this.getDetail();
      }
    },
    getDetail() {
      this.$post("/biz/course/display", { courseId: this.courseId }).then(
        (result) => {
          if (result.status === "0") {
            let data = result.data;
            const ret = {
              courseName: data.courseName,
              context: data.context,
              thumbnailId: data.logo,
              thumbnail: data.logoURL,
              resource: "",
              buyId: data.buyId || "",
              kpointLessonNum: data.kpointLessonNum, //总学时
              kpointTotalNum: data.kpointTotalNum, //总课时
              durationStr: data.durationStr, //视频总时长
              duration: data.duration, //视频总时长
              trainTypeId: data.trainTypeId
            };
            this.styleType = data.chapterSchema ? 1 : 2;
            this.ruleForm = ret;
            this.disabled = false;
            editor.txt.html(data.context);
            let list = [];
            if (data.chapterSchema) {
              data.nodes.forEach((element, index) => {
                let nodeList = [];
                list.push({
                  ind: new Date().getTime(),
                  label: element.catalogName,
                  id: element.courseKpointId,
                  children: nodeList,
                  level: 1,
                  buyId: element.buyId || "",
                  kpointLessonNum: element.kpointLessonNum,
                  kpointDuration: element.kpointDuration,
                  kpointDurationStr: element.kpointDurationStr,
                  kpointVideoUrl:element.kpointVideoUrl
                });
                (element.child || []).forEach((obj, ind) => {
                  nodeList.push(this.section2Item(obj, ind, index));
                });
              });
            } else {
              data.nodes.forEach((element, ind) => {
                list.push(this.section2Item(element, "", ind));
              });
            }
            this.mapData(list);
            // this.data = list;
          }
        }
      );
    },
    section2Item(obj, index, pindex) {
      let children = [];

      if (obj.paperId) {
        children = [
          {
            ind: new Date().getTime(),
            paperId: obj.paperId,
            paperName: obj.paperName,
            label: obj.paperName,
            level: 3,
          },
        ];
      }

      let listArr = [];
      if (obj.kpointVideoId) {
        obj.list = listArr;
        listArr.push({
          kpointVideoId: obj.kpointVideoId,
          kpointName: obj.kpointName,
          kpointDuration: obj.kpointDuration,
          kpointId: obj.kpointId,
          kpointLessonNum: obj.kpointLessonNum,
          kpointLogo: obj.kpointLogo,
          kpointType: obj.kpointType,
          qualificationId: obj.qualificationId,
          kpointDeleted: obj.kpointDeleted, // 课件是否删除了
          progress: 100,
          buyId: obj.buyId || "",
          kpointLessonNum: obj.kpointLessonNum,
          kpointDuration: obj.kpointDuration,
          kpointDurationStr: obj.kpointDurationStr,
        });
      } else if (obj.paperId) {
        obj.list = listArr;
        listArr.push({
          progress: 100,
          buyId: obj.buyId || "",
          paperId: obj.paperId,
          paperName: obj.paperName,
        });
      }
      //判断kpointId存在，paperId存在，带试卷的课件，kpointId不存在，paperId存在是单元测试
      if (obj.kpointId) {
        return {
          ind: new Date().getTime(),
          label: obj.catalogName,
          id: obj.courseKpointId,
          level: 2,
          list: listArr,
          children: children,
          kpointLessonNum: obj.kpointLessonNum,
          kpointDuration: obj.kpointDuration,
          kpointDurationStr: obj.kpointDurationStr,
          // buyId: obj.buyId || '',
        };
      } else {
        return {
          ind: new Date().getTime(),
          label: obj.catalogName,
          id: obj.courseKpointId,
          level: 2,
          list: listArr,
          children: [],
          paperId: obj.paperId,
          paperName: obj.paperName,
          // buyId: obj.buyId || '',
        };
      }
    },
  
    // 富文本
    editorInit() {
      editor = new E(this.$refs.editor);
      editor.config.menus = [
        "head", // 标题
        "bold", // 粗体
        "fontSize", // 字号
        "italic", // 斜体
        "underline", // 下划线
        "strikeThrough", // 删除线
        "foreColor", // 文字颜色
        "backColor", // 背景颜色
        "list", // 列表
        "justify", // 对齐方式
        "quote", // 引用
        "emoticon", // 表情
        "image", // 插入图片
        "table", // 表格
        "undo", // 撤销
        "redo", // 重复
      ];
      editor.config.zIndex = 100;
       editor.config.uploadImgMaxSize = 5 * 1024 * 1024;
      // editor.config.pasteFilterStyle = "text";
      editor.config.pasteIgnoreImg = true;
      editor.config.pasteFilterStyle = false;
  editor.config.customUploadImg = (files, insert) => {
        let extension = files[0].name.substring(
          files[0].name.lastIndexOf(".") + 1,
          files[0].name.length
        );
        let formData = new FormData();
        formData.append("file ", files[0]);
        formData.append("fileType ", extension);
        formData.append("folder ", "LECTURER");
        formData.append("isPublic ", true);
        this.$Postformat("/sys/upload", formData)
          .then((result) => {
            insert(result.data.fileURL);
          })
          .catch(() => {
            setTimeout(() => {
              this.$message({
                type: "warning",
                message: "上传失败",
              });
            }, 300);
          });
      };
      editor.config.onchange = (html) => {
        this.ruleForm.context = html;
      };
      editor.config.pasteTextHandle = function (content) {
        return content + "<p></p>";
        // content 即粘贴过来的内容（html 或 纯文本），可进行自定义处理然后返回
      };
      editor.create();
    },
    /**
     * 拖动按钮
     */
    // 快速关联
    quickAssociate() {
      this.$confirm(
        "根据你上传课件名称与章节项名称进行快速关联，是否立即快速关联？",
        "快速关联",
        {
          type: "warning",
        }
      )
        .then(() => {
          const { data, uploadFileList } = this;
          let flag = false;
          if (this.styleType == 1) {
            data.map((item) => {
              (item.children || []).map((it) => {
                flag = this.associate(it);
                return it;
              });
              return item;
            });
          } else {
            data.map((it) => {
              flag = this.associate(it);
              return it;
            });
          }
          if (!flag) {
            this.$message.warning("没有可快速关联的课件或章节");
          } else {
            this.$message.success("关联完成");
          }
        })
        .catch(() => {});
    },
    associate(it) {
      // 已有视频 不应继续关联
      if (!(it.list || []).length) {
        const sameIndex = this.uploadFileList.findIndex(
          (value) => value.dispName === it.label
        );
        if (sameIndex !== -1) {
          // 章节树赋值 + 课件删除
          this.wrapFileInfo(this.uploadFileList[sameIndex], it);
          this.uploadFileList.splice(sameIndex, 1);
           this.length1.push(it);
          return true;
        }
      }
      return false;
    },
    /**
     * 拖动事件
     */
    // tree中视频拖动 去冒泡
    stopTreeMove() {
      this.draggable = false;
    },
    canTreeMove() {
      this.draggable = true;
    },
    // 添加课件
    doAddCourse(node, data) {
      this.datas = data;
      // if (data.list.length <= 0) {
      //   state = "all";
      // } else if (data.list[0].kpointId || data.list[0].kpointVideoId) {
      //   state = "course";
      // } else {
      //   state = "unitTest";
      // }
      this.$refs.enterpriseInternalTrainingMyCourseTable.showPopUp();
    },
    // 批量添加课件
    doAddCourseMore(node, data) {
      this.datas = data;
      this.$refs.enterpriseInternalTrainingMyCourseTableMore.showPopUp();
      //courseTableMoreBack
    },
    // 批量添加课件
    doAddCourseMoreStyle2() {
      this.addBtnIsOut = true;
      this.$refs.enterpriseInternalTrainingMyCourseTableMore.showPopUp();
      //courseTableMoreBack
    },
    doChooseStyle() {
      this.$refs.MenuStyle.showPopUp();
    },
    handleDragStart(node) {
      console.log("drag start", node);
    },
    handleDragEnter(draggingNode, dropNode) {
      console.log("tree drag enter: ", dropNode.label);
    },
    handleDragLeave(draggingNode, dropNode) {
      console.log("tree drag leave: ", dropNode.label);
    },
    handleDragOver(draggingNode, dropNode) {
      console.log("tree drag over: ", dropNode.label);
    },
    handleDragEnd(draggingNode, dropNode, dropType) {
      console.log("tree drag end: ", dropNode && dropNode.label, dropType);
    },
    handleDrop(draggingNode) {
      console.log("tree drag finish: ", draggingNode);
      this.mapData(this.data);
      this.updataBack2Index();
    },
    // tree中非同级 不能拖拽
    allowDrop(draggingNode, dropNode, type) {
      const tlevel = dropNode.data.level;
      const plevel = draggingNode.data.level;
      if (plevel === tlevel && type !== "inner") {
        // 同级
        return true;
      } else if (plevel === tlevel + 1 && type === "inner") {
        // 子集
        return true;
      } else {
        return false;
      }
    },
    allowDrag(draggingNode) {
      return draggingNode.data.label.indexOf("三级 3-2-2") === -1;
    },
    // 一个章节只能挂载一个视频
    listCheckMove(ctx) {
      let dargEl = ctx.to;
      dargEl = dargEl ? dargEl.querySelectorAll(".trees-btns")[0] : "";
      if (dargEl) {
        const canDarg = dargEl.getAttribute("canDarg");
        if (canDarg && canDarg !== "0") {
          return false;
        }
      }
    },
    nodeChange(evt, tarData) {
      if (evt.added) {
        let el = evt.added.element;
        this.$confirm(
          "你确定要将课件[" +
            el.dispName +
            "]与[" +
            tarData.label +
            "]进行关联么?",
          "提示"
        ).then(() => {
          //如果当前节点在编辑且未输入课件名,则直接将文件名赋值给课件名
          if (tarData.isEdit == "1" && this.treeLable == "") {
            this.treeLable = el.dispName;
            this.length1.push(el);
            this.$refs.input.focus();
          } else {
            this.length1.push(el);
          }
          this.wrapFileInfo(el, tarData);
          this.uploadFileList.splice(this.uploadFileList.indexOf(el), 1);
        });
      }
      this.updataBack2Index();
    },
    getIndex(el) {
      if (!el) {
        return -1;
      }
      let index = 0;
      do {
        index++;
        el = el.previousElementSibling;
      } while (el);
      return index;
    },
    /**
     * 上传
     */
    // 重新遍历记录索引 添加|删除|拖拽都会触发
    updataBack2Index() {
      clearTimeout(updataTimer);
      updataTimer = setTimeout(this.updataIndexReset, 300);
    },
    // 变更文件队列dom索引
    updataIndexReset() {
      const { data, uploadFileList, updateRecord } = this;
      // tree
      data.forEach((item, index) => {
        (item.children || []).forEach((it, ix) => {
          // 是否是需要上传的dom
          if ((it.list || []).length) {
            const fileData = it.list[0];
            if (fileData.name && fileData.progress !== 100) {
              const sameIndex = updateRecord.findIndex(
                (value) => value.id === fileData.id
              );
              if (sameIndex !== -1) {
                updateRecord[sameIndex].indexs = [index, ix];
              } else {
                fileData.indexs = [index, ix];
                updateRecord.push(fileData);
              }
            }
          }
        });
      });

      // list
    },
    // 更新上传状态
    changeUpdateStu(files) {
      const updateRecord = this.updateRecord;
      const index = updateRecord.findIndex(
        (value) => value.name === files.name
      );
      if (index !== -1) {
        const objIndex = updateRecord[index].indexes || [];
        const progress = files.progress || 0;
        if (objIndex.length === 2) {
          this.data[objIndex[0]].children[objIndex[1]].list[0].progress =
            progress;
        } else {
          this.uploadFileList[objIndex[0]].progress = progress;
        }
      }
    },
    // 添加
    doTreeAppendone() {
      this.disabled = true;
      this.styleStu = false;
      this.CreatNameBack();
    },
    doTreeAppend(node, data) {
      this.disabled = true;
      this.datas = data;
      this.styleStu = true;
      this.CreatNameBack();
      this.updataBack2Index();
    },
    //删除
    doTreeDel(node, data) {
      const parent = node.parent;
      const children = parent.data.children || parent.data;
      const index = children.indexOf(data);
      if (data.level < 3 && data.id) {
        this.deletedChapter.push(data.id);
      }
      if (data.level == 3) {
        this.$confirm("你确定删除该试卷吗？", "删除", {
          confirmButtonText: "确定",
          cancelButtonText: "取消",
          type: "warning",
        })
          .then(() => {
            children.splice(index, 1);
            delete parent.data.list[0].paperId;
            delete parent.data.list[0].paperName;
            this.$message({
              type: "success",
              message: "删除成功!",
            });
            this.disabled = false;
            this.mapData(this.data);
          })
          .catch(() => {
            this.$message({
              type: "info",
              message: "已取消删除",
            });
          });
      } else if (data.level == 2) {
        let name = "二级";
        if (this.styleType == 2) {
          name = "一级";
        }
        this.$confirm(
          "你确定删除该" + name + "项及关联的所有课件？",
          "删除" + name + "项",
          {
            confirmButtonText: "确定",
            cancelButtonText: "取消",
            type: "warning",
          }
        )
          .then(() => {
            children.splice(index, 1);
            console.log(data);
             let lessonNum = 0;
            if (data.kpointLessonNum) {
              this.ruleForm.kpointLessonNum -= data.kpointLessonNum;
              lessonNum++;
              this.ruleForm.kpointTotalNum -= lessonNum;
            }
            if (data.kpointDuration) {
              this.ruleForm.duration -= data.kpointDuration;
            }
            this.disabled = false;
            this.$message({
              type: "success",
              message: "删除成功!",
            });
            this.mapData(this.data);
          })
          .catch(() => {
            this.$message({
              type: "info",
              message: "已取消删除",
            });
          });
      } else {
        this.$confirm(
          "你确定删除该一级项及下面所有二级项内容？",
          "删除一级项",
          {
            confirmButtonText: "确定",
            cancelButtonText: "取消",
            type: "warning",
          }
        )
          .then(() => {
            let lessonNum = 0;
            console.log(data.children.length);
            for (let i = 0; i < data.children.length; i++) {
              if (data.children[i].kpointLessonNum) {
                this.ruleForm.kpointLessonNum -=
                  data.children[i].kpointLessonNum;
              }
              if (data.children[i].kpointDuration) {
                this.ruleForm.duration -= data.children[i].kpointDuration;
              }
            }
            this.ruleForm.kpointTotalNum -= data.children.length;
            children.splice(index, 1);
            this.disabled = false;
            this.$message({
              type: "success",
              message: "删除成功!",
            });
            this.mapData(this.data);
          })
          .catch(() => {
            this.$message({
              type: "info",
              message: "已取消删除",
            });
          });
      }
      this.updataBack2Index();
    },
    doTreeEdit(node, data) {
      this.treeLable = data.label;
      this.disabled = true;
      this.$set(node, "isEdit", 1);
      this.$set(data, "isEdit", 1);
      this.$nextTick(() => {
        this.$refs.input.focus();
      });
    },
    submitEdit(node, data) {
      // treeLable----目录名称
      if (this.treeLable.trim()) {
        this.$set(data, "label", this.treeLable);
        this.$set(data, "isEdit", 0);
        this.$set(node, "isEdit", 0);
        this.treeLable = "";
        this.disabled = false;
      } else {
        this.$message.warning("请输入名称");
      }
    },
    handleEnter(event) {
      if (this.treeLable.trim()) {
        event.target.blur();
      } else {
        this.$message.warning("请输入名称");
      }
      // 回车失去焦点单独写 // 更新操作应该放到失去焦点的事件中，后面调用接口时重新写blur方法
    },
    // 弹窗回调
    styleBack(ret) {
      this.disabled = true;
      this.styleType = ret;
      if (ret == 1) {
        this.CreatNameBack();
      } else {
        this.textshow = false;
        this.disabled = false;
      }
    },
    // 填写目录名称
    CreatNameBack() {
      if (this.styleStu) {
        // 样式1下的二级项
        const children = this.datas.children;
        children.push({
          ind: new Date().getTime(),
          level: 2,
          label: "",
          isEdit: "1",
          children: [],
          list: [],
        });
        this.$set(this.datas, "children", children);
        this.styleStu = false;
      } else if (this.styleType == 1) {
        //样式1
        this.data.push({
          ind: new Date().getTime(),
          level: 1,
          label: "",
          isEdit: "1",
          children: [],
        });
      } else {
        // 样式2
        this.data.push({
          ind: new Date().getTime(),
          level: 2,
          label: "",
          isEdit: "1",
          children: [],
          list: [],
        });
        // this.disabled = false;
      }
      this.mapData(this.data);
    },
    couseBack(ret) {
      console.log(ret);
      let lessonNum = 0;
      //单个课件
      if (ret !== undefined) {
        if (this.datas.label == "") {
          if (ret.kpointName) {
            this.$set(this.datas, "label", ret.kpointName);
            this.$set(this.datas, "kpointLessonNum", ret.kpointLessonNum);
            this.$set(this.datas, "kpointDuration", ret.kpointDuration);
            this.ruleForm.kpointLessonNum += ret.kpointLessonNum;
            this.ruleForm.duration += ret.kpointDuration;
            if (ret.kpointVideoUrl) {
              lessonNum++;
            }
            this.ruleForm.kpointTotalNum += lessonNum;
          } else {
            this.$set(this.datas, "label", ret.paperName);
            this.$set(this.datas, "kpointLessonNum", ret.kpointLessonNum);
            this.$set(this.datas, "kpointDuration", ret.kpointDuration);
            if (ret.kpointVideoUrl) {
              lessonNum++;
            }
            this.ruleForm.kpointTotalNum += lessonNum;
          }
          this.$set(this.datas, "isEdit", 0);
          this.disabled = false;
        } else {
          if (this.datas.kpointDuration) {
            this.ruleForm.duration -= this.datas.kpointDuration;
          }
          if (this.datas.kpointLessonNum) {
            this.ruleForm.kpointLessonNum -= this.datas.kpointLessonNum;
          }
          if (this.datas.kpointVideoUrl && this.datas.list[0].kpointId) {
            lessonNum--;
          }
          this.ruleForm.kpointTotalNum -= lessonNum;
          this.$set(this.datas, "kpointLessonNum", ret.kpointLessonNum);
          this.$set(this.datas, "kpointDuration", ret.kpointDuration);
          this.ruleForm.kpointLessonNum += ret.kpointLessonNum;
          console.log(ret.kpointDuration);
          this.ruleForm.duration += ret.kpointDuration;
          if (ret.kpointVideoUrl && !this.datas.list.length) {
            lessonNum++;
          }
          this.ruleForm.kpointTotalNum += lessonNum;
        }
      
        this.$set(this.datas, "list", [
          {
            ind: new Date().getTime(),
            kpointId: ret.kpointId,
            kpointName: ret.kpointName,
            name: ret.kpointName,
            progress: 100,
            teacher: ret.teacherName,
            category: ret.trainTypeName,
            kpointLessonNum: ret.kpointLessonNum,
            kpointVideoId: ret.kpointVideoId,
            kpointVideoUrl: ret.kpointVideoUrl,
            kpointSource: ret.kpointSource,
            kpointLogo: ret.kpointLogo,
            kpointDuration: ret.kpointDuration,
            kpointType: ret.kpointType,
            qualificationId: ret.qualificationId,
            paperId: ret.paperId,
            paperName: ret.paperName,
            buyId: ret.buyId || "",
          },
        ]);
        console.log(this.datas);
      }
    },
    // 批量选课件
    courseTableMoreBack(ret) {
      if (this.datas.label == "") {
        this.$set(this.datas, "label", ret.kpointName);
        this.$set(this.datas, "isEdit", 0);
        this.disabled = false;
        console.log('888hh');
      }
      const childBack = [];
      if (!this.addBtnIsOut) {
        for (let i = 0; i < ret.length; i++) {
          const element = ret[i];
          const paperChild = [];
          // if (element.paperId) {
          //   paperChild.push({
          //     ind: new Date().getTime(),
          //     level: 3,
          //     list: [],
          //     label: element.paperName,
          //     paperName: element.paperName,
          //     paperId: element.paperId,
          //   });
          // }
          childBack.push({
            ind: new Date().getTime(),
            level: 2,
            list: [
              {
                ind: new Date().getTime(),
                kpointId: element.kpointId,
                kpointName: element.kpointName,
                name: "eda ",
                progress: 100,
                teacher: '无讲师',
                category: element.trainTypeName,
                kpointLessonNum: '1',
                kpointVideoId: element.kpointVideoId,
                kpointVideoUrl: element.kpointVideoUrl,
                kpointSource: element.kpointSource,
                kpointLogo: element.kpointLogo,
                kpointDuration: element.kpointDuration,
                kpointType: element.kpointType,
                qualificationId: '-1000',
                buyId: element.buyId || "",
              },
            ],
            label: element.kpointName,
            children: paperChild,
            kpointLessonNum: 1,
            kpointDuration: element.kpointDuration,
            kpointVideoUrl: element.kpointVideoUrl,
          });
          this.ruleForm.kpointLessonNum += element.kpointLessonNum;
          this.ruleForm.duration += element.kpointDuration;
          let lessNum = 0;
          if (element.kpointVideoUrl) {
            lessNum++;
            this.ruleForm.kpointTotalNum += lessNum;
          }
        }
        this.$set(this.datas, "children", [
          ...this.datas.children,
          ...childBack,
        ]);
      } else {
        for (let i = 0; i < ret.length; i++) {
          const element = ret[i];
          const paperChild = [];
          // if (element.paperId) {
          //   paperChild.push({
          //     ind: new Date().getTime(),
          //     level: 3,
          //     list: [],
          //     label: element.paperName,
          //     paperName: element.paperName,
          //     paperId: element.paperId,
          //   });
          // }
          const moreCourse = [];
          moreCourse.push({
            ind: new Date().getTime(),
            level: 2,
            label: element.kpointName,
             kpointLessonNum: element.kpointLessonNum,
              kpointDuration: element.kpointDuration,
            isEdit: "0",
            children: paperChild,
            list: [
              {
                ind: new Date().getTime(),
                kpointId: element.kpointId,
                kpointName: element.kpointName,
                name: element.kpointName,
                progress: 100,
                teacher: '无讲师',
                category: element.trainTypeName,
                kpointLessonNum: element.kpointLessonNum,
                kpointVideoId: element.kpointVideoId,
                kpointVideoUrl: element.kpointVideoUrl,
                kpointSource: element.kpointSource,
                kpointLogo: element.kpointLogo,
                kpointDuration: element.kpointDuration,
                kpointType: element.kpointType,
                qualificationId: '-1000',
                buyId: element.buyId || "",
                // kpointDurationStr: element.kpointDurationStr,
              },
            ],
            
          });
          this.ruleForm.kpointLessonNum += element.kpointLessonNum;
          this.ruleForm.duration += element.kpointDuration;
          let lessNum = 0;
          if (element.kpointVideoUrl) {
            lessNum++;
            this.ruleForm.kpointTotalNum += lessNum;
          }
          this.data = [...this.data, ...moreCourse];
        }
         
      }
      this.mapData(this.data);
    },
    handlebusinessLicense2(res) {
      this.uploadFetch(res.raw);
    },
    uploadFetch(file) {
      const fileType = file.name.slice(
        file.name.lastIndexOf(".") + 1,
        file.name.length
      );
      let formData = new FormData();
      formData.append("file", file);
      formData.append("fileType", fileType);
      formData.append("folder ", "LECTURER");
      const that = this;
      this.$Postformat("/sys/upload", formData).then((ret) => {
        that.ruleForm.thumbnail = ret.data.fileURL || "";
        that.ruleForm.thumbnailId = ret.data.fileKey || "";
      });
    },
    handleClose() {
      (this.dialogVisible1 = false), (this.status = 3);
      distorySwigger();
    },
    // 预览
    showVideo(data) {
      console.log(data);
      let _this = this;
      //单元测试打开是题库
      if (data.list[0].kpointVideoId) {
        _this
          .$post("/biz/courseware/auth", {
            videoId: data.list[0].kpointVideoId,
          })
          .then((result) => {
            _this.videoId = data.list[0].kpointVideoId;
            _this.kpointSource = data.list[0].kpointSource || '10';
            _this.videoType = result.data.trySee;
            _this.videoTime = result.data.duration;
            _this.videotitle = data.label;
            _this.dialogVisible1 = true;
          });
      } else if (data.list[0].kpointVideoUrl) {
        _this.videoId = data.list[0].kpointVideoId;
        _this.kpointVideoUrl = data.list[0].kpointVideoUrl;
        _this.kpointSource = data.list[0].kpointSource;
        _this.videotitle = data.label;
        _this.dialogVisible1 = true;
      }
    },
   
    onCancel() {
      this.$router.back();
    },
    onSubmit(auditState) {
      sessionStorage.setItem("refresh", auditState); // 缓存刷新用
      const params = {
        courseId: this.courseId,
        courseName: this.ruleForm.courseName,
        context: this.ruleForm.context,
        logo: this.ruleForm.thumbnailId,
        auditState: auditState,
        salesState: '10',
        deletedChapter: this.deletedChapter,
        chapterSchema: this.styleType == 1 ? true : false,
      };
      // 培训类型
      if (this.ruleForm.trainTypeId) {
        params.trainTypeId = this.ruleForm.trainTypeId;
      }
      //
      let dataSource = []; // 最外层列表；
      this.data.forEach((item) => {
        let sec = {
          catalogName: item.label,
          courseKpointId: item.id,
        };
        dataSource.push(sec);
        if (this.styleType == 1) {
          sec.section = true;
          sec.nodeList = [];
          sec.buyId = item.buyId || "";
          item.children.forEach((el) => {
            let node = {
              catalogName: el.label,
              courseKpointId: el.id,
              section: false,
            };
            this.item2Section(el, node);
            sec.nodeList.push(node);
          });
        } else {
          sec.section = false;
          this.item2Section(item, sec);
        }
      });
      if (this.styleType == 1) {
        params.sectionList = dataSource;
      } else {
        params.nodeList = dataSource;
      }
      // 检测是否有重复课件或试卷引入
      if (!this.checkCourse(params)) {
        return;
      }
      const ruleForm = this.$refs.ruleForm;
      ruleForm.validate((valid) => {
        // 校验表单
        if (!valid) {
          this.$message.warning("请检查必填项");
          ruleForm.clearValidate();
          return;
        }
        if (editor.txt.text().trim() == "") {
          this.$message.warning("请检查必填项");
          return;
        }
        let url = "/biz/course/insert";
        if (params.courseId) {
          url = "/biz/course/edit";
        }
        let sourceList = [];
        if (this.styleType == 1) {
          sourceList = params.sectionList.flatMap((item) => item.nodeList);
        } else {
          sourceList = params.nodeList;
        }
        let re = sourceList.find((item) => {
          //判断 kpointId存在，kpointVideoId不存在，弹未关联提示
          if (item.kpointId && !item.kpointVideoId) {
            return item;
          }
          //判断 kpointId不存在，kpointVideoId不存在，paperId不存在 ，弹未关联提示
          if (!item.kpointId && !item.kpointVideoId && !item.paperId) {
            return item;
          }
        });
        //如果有未关联的章节,二次提示
        if (re) {
          //判断没有关联课件的章节是否存在kpointId，有是课件，没有是单元测试

          this.$confirm("你有章节还未关联课件,确认保存么?", "提示")
            .then((re) => {
              this.reqRemote(url, params);
            })
            .catch((re) => {});
        } else {
          this.reqRemote(url, params, auditState);
        }
      });
    },
    reqRemote(url, params, auditState) {
      this.$post(url, params)
        .then((res) => {
          if (res.status == 0) {
            // this.courseId = res.data ? res.data : this.courseId;
            // this.getDetail(this.courseId);
            this.$router.push({
                path: "/web/CustomPrograms/enterpriseInternalTrainingMyCourseCourseWare",
                query: {
                  refresh: true,
                  active: "first",
                  isAdd: params.courseId ? false : true,
                },
              });

           
          }
        })
        .catch((err) => {});
    },
    item2Section(el, section) {
      if (el.list && el.list.length && el.list[0].progress == 100) {
        section.kpointVideoId = el.list[0].kpointVideoId;
        section.kpointDuration = el.list[0].kpointDuration;
        section.kpointId = el.list[0].kpointId;
        section.kpointLessonNum = el.list[0].kpointLessonNum;
        section.kpointLogo = el.list[0].kpointLogo;
        section.kpointType = el.list[0].kpointType;
        section.qualificationId = el.list[0].qualificationId;
        section.paperId = el.list[0].paperId;
        section.paperName = el.list[0].paperName;
        section.buyId = el.list[0].buyId || "";
        if (el.children && el.children.length) {
          section.paperId = el.children[0].paperId;
          section.paperName = el.children[0].label;
        }
      }
      return section;
    },
    checkCourse(data) {
      let sourceList = [];
      if (this.styleType == 1) {
        sourceList = data.sectionList.flatMap((section) => section.nodeList);
      } else {
        sourceList = data.nodeList;
      }
      let paperIdSet = new Set();
      let paperIdTestSet = new Set();
      let kpointIdSet = new Set();
      let repeatedPaperNames = [];
      let repeatedPaperTestNames = []; //单元测试习题多次引用的
      let repeatedKpointNames = [];
      sourceList.forEach((source) => {
        if (source.kpointId) {
          if (source.paperId && paperIdSet.has(source.paperId)) {
            repeatedPaperNames.push(source.paperName);
          } else {
            paperIdSet.add(source.paperId);
          }
        } else {
          if (source.paperId && paperIdTestSet.has(source.paperId)) {
            repeatedPaperTestNames.push(source.paperName);
          } else {
            paperIdTestSet.add(source.paperId);
          }
        }

        if (source.kpointId && kpointIdSet.has(source.kpointId)) {
          repeatedKpointNames.push(source.catalogName);
        } else {
          kpointIdSet.add(source.kpointId);
        }
      });
      if (repeatedPaperTestNames.length) {
        this.$message.warning(
          "单元测试[" + repeatedPaperTestNames.join(",") + "]被多次引用,请检查"
        );
        return false;
      }
      if (repeatedPaperNames.length) {
        this.$message.warning(
          "试卷[" + repeatedPaperNames.join(",") + "]被多次引用,请检查"
        );
        return false;
      }
      if (repeatedKpointNames.length) {
        this.$message.warning(
          "课件[" + repeatedKpointNames.join(",") + "]被多次引用,请检查"
        );
        return false;
      }
      return true;
    },
    onAddFile(file) {
      this.uploadFileList.push(file);
    },
    delUploadFile(file) {
      this.uploadFileList.splice(this.uploadFileList.indexOf(file), 1);
      this.$refs.batchUploader.remFile(file);
    },
    uploadFileProgress(progress, videoId) {
      let tar = this.uploadFileList.find((file) => file.videoId == videoId);
      console.log(tar);
      let formatedProgress = parseInt(progress * 100);
      if (tar) {
        this.$set(tar, "progress", formatedProgress);
      } else {
        this.updateNodeProgress(this.data, videoId, formatedProgress);
      }
    },
    updateNodeProgress(parent, videoId, progress) {
      for (let i = 0; i < parent.length; i++) {
        let item = parent[i];
        if (item.videoId == videoId) {
          this.$set(item.list[0], "progress", progress);
          return;
        }
        if (item.children) {
          this.updateNodeProgress(item.children, videoId, progress);
        }
      }
    },
    uploadFileSuccess(videoId) {
      this.uploadFileProgress(1, videoId);
    },
    wrapFileInfo(file, target) {
      this.$set(target, "list", []);
      target.list.push({
        ind: new Date().getTime(),
        level: 3,
        list: [],
        name: file.dispName,
        kpointName: file.dispName,
        kpointVideoId: file.videoId,
        progress: file.progress,
        kpointLessonNum: '1',
        qualificationId: '-1000',
        teacher:  '无讲师',
        kpointType: 10,
      });
      target.videoId = file.videoId;
    },
    fileFilted(a, b) {
      let itemCls = a.item.className.split(" ");
    if (itemCls.includes("uncompleted")) {
        this.$message.warning("只有上传完才能关联章节");
      }
    },
  
    mapData(data) {
      //样式1下
      data.forEach((item, i) => {
        item["chapterNum"] = Number(i) + 1;
        item.children.forEach((el, k) => {
          el["chapterNum"] = Number(i) + 1 + "-" + (Number(k) + 1);
        });
      });

      this.$nextTick(() => {
        this.data = data;
      });
    },
    //时间转换
    getTime1(val) {
      if (!val) {
        return "0秒";
      }
      let h = parseInt(val / 60 / 60);
      let m = parseInt((val % 3600) / 60);
      let s = val % 60;
      if (h < 9) {
        h = "0" + h;
      }
      if (m < 9) {
        m = "0" + m;
      }
      if (s < 9) {
        s = "0" + s;
      }
      return (
        (h != "00" ? h + "时" : "") +
          (m != "00" ? m + "分" : "") +
          (s != "00" ? s + "秒" : "") || ""
      );
    },
  },
  beforeDestroy: function () {
    if (updataTimer) {
      clearTimeout(updataTimer);
    }
  },
};
</script>
<style lang="less" scoped>
.createdCourse {
  .ellipsis {
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
    width: 240px;
    font-size: 14px;
  }
  .level {
    padding: 3px;
    border-radius: 6px;
    color: #333;
    background-color: #e0f2ff;
    min-width: 20px;
    text-align: center;
  }
}

.formCom {

  .chapterl-btns {
    .el-button {
      padding: 8px;
    }
  }
  .el-cascader {
    width: 100%;
  }
  .el-progress {
    width: 200px;
  }
  .chapterl-trees {
    padding: 15px 10px 15px 0;
    /deep/.el-tree-node__content {
      height: 36px;
      margin-bottom: 15px;
    }
    .draggable-item {
      margin-left: 10px;
      em {
        margin-right: 5px;
      }
    }

    .trees-btns {
      .showbtn {
        color: #fff;
      }
      margin-left: 15px;
      & > em,
      .handle > em {
        color: #4089fa;
        font-size: 14px;
        cursor: pointer;
        &:not(:last-child) {
          margin-right: 5px;
        }
      }
    }
  }
  .chapteru-lists {
    padding: 15px 15px 15px 0;
    /deep/.el-divider--horizontal {
      margin: 5px;
    }
    .cl-subhint {
      display: flex;
      span {
        font-size: 14px;
        color: #666;
        &:nth-child(2) {
          margin-left: 15px;
        }
      }
      em {
        margin: 0 5px;
      }
    }
    .cl-btns {
      display: flex;
      align-items: center;
      width: 4rem;
      justify-content: space-between;
      i {
        color: #999999;
        cursor: pointer;
        &:not(:last-child) {
          margin-right: 5px;
        }
      }
    }
  }
  .el-icon-receiving {
    width: 42px;
    height: 42px;
    font-size: 40px;
    color: #999;
    margin-right: 15px;
  }
  .pageForm-btns {
    padding: 15px 0;
    text-align: center;
    .el-button {
      width: 10rem;
      span {
        min-width: 4em;
      }
    }
  }
  .file-list-group {
    .list-group-item {
      display: flex;
      align-items: center;
      margin-bottom: 10px;
    }
  }
  .tree-drag-group {
    display: flex;
    align-items: center;
    font-size: 14px;
    .list-group-item {
      margin-left: 5px;
      display: inline-block;
      .el-icon-receiving {
        font-size: 22px;
        color: #2d2;
      }
      .df {
        display: none;
      }
    }
  }
  .bgc-height {
    background-color: #f4ff00;
  }
}

.btnbox {
  .el-button.is-disabled,
  .el-button.is-disabled:focus,
  .el-button.is-disabled:hover {
    background: #a9a9a9;
    border-color: #a9a9a9;
    color: #fff;
  }
}
</style>
<style lang="less" scoped>


.courseIcon {
  width: 14px;
  height: 14px;
  margin-right: 5px;
  display: inline-block;
  background: url("../../../assets/courseIcon.png") no-repeat;
  background-size: cover;
}

.paperIcon {
  width: 14px;
  height: 14px;
  margin-right: 5px;
  display: inline-block;
  background: url("../../../assets/paperIcon.png") no-repeat;
  background-size: cover;
}

.nullIcon {
  width: 14px;
  height: 14px;
  margin-right: 5px;
}
</style>
