<template>
  <el-dialog
    :visible.sync="dialogVisible"
    top="2%"
    width="60%"
    title="关联课件"
    :center="true"
    @close="doClose"
  >
    <section class="CourseTablekponit">
      <div class="operationControl" style="border: 0">
        <div class="searchbox">
          <div title="课件名称" class="searchboxItem">
            <span class="itemLabel">课件名称:</span>
            <el-input
              clearable
              v-model="searchData.kpointName"
              type="text"
              size="small"
              placeholder="请输入课件名称"
            />
          </div>
          <div title="培训类型" class="searchboxItem">
            <span class="itemLabel">培训类型:</span>
            <el-cascader
              v-model="searchData.trainTypeId"
              :options="TrainTypeList"
              filterable
              :show-all-levels="false"
              :props="{
                emitPath: false,
                value: 'id',
                label: 'label',
                children: 'children',
                checkStrictly: true,
              }"
              size="small"
            ></el-cascader>
          </div>
          <div title="上传时间" class="searchboxItem">
            <span class="itemLabel">上传时间:</span>
            <el-date-picker
              v-model="searchData.uploadTime"
              type="daterange"
              range-separator="至"
              value-format="timestamp"
              start-placeholder="开始日期"
              end-placeholder="结束日期"
            ></el-date-picker>
          </div>
          <div>
            <el-button class="bgc-bv" @click="getData()">查询</el-button>
          </div>
        </div>
      </div>
      <div class="framePage-scroll">
        <div class="ovy-a">
          <el-table
            ref="multipleTable"
            :data="tableData"
            :height="tableHeight"
            size="small"
            tooltip-effect="dark"
            row-key="kpointId"
            style="width: 100%"
            stripe
            border
          >
            <el-table-column label width="35px" align="center">
              <template slot-scope="scope">
                <el-radio v-model="radio" :label="scope.row" />
              </template>
            </el-table-column>
            <el-table-column
              label="课件名称"
              align="center"
              show-overflow-tooltip
              prop="kpointName"
              width="200"
            />
            <el-table-column label="培训类型" align="left" prop="categoryName">
              <template slot-scope="scope">
                {{ scope.row.categoryName.split("##")[0] }}
              </template>
            </el-table-column>
            <el-table-column
              label="时长"
              align="center"
              show-overflow-tooltip
              prop="kpointDuration"
            >
              <template slot-scope="scope">{{
                getTime(scope.row.kpointDuration) || "--"
              }}</template>
            </el-table-column>
            <el-table-column label="创建时间" align="left" prop="createTime">
              <template slot-scope="scope">
                {{ scope.row.createTime }}
              </template>
            </el-table-column>
            <Empty slot="empty" />
          </el-table>
        </div>
      </div>
      <PageNum :apiData="apiData" @sizeChange="sizeChange" @getData="getData" />
      <span class="dialog-footer" style="margin-top: 10px">
        <div>
          <el-button class="bgc-bv" @click="doClose">取消</el-button>
          <el-button class="bgc-bv" @click="doOk">确定</el-button>
        </div>
      </span>
    </section>
  </el-dialog>
</template>
<script>
import Empty from "@/components/Empty.vue";
import PageNum from "@/components/PageNum.vue";
import List from "@/mixins/List";
import { mapGetters } from "vuex";
export default {
  name: "courseMoreTable",
  components: {
    Empty,
    PageNum,
  },
  mixins: [List],
  data() {
    return {
      //搜索条件
      searchData: {
        kpointName: "", //课件名称
        trainTypeId: "", //培训类型
        uploadTime: "", //上传时间
      },
      radio: "",
      props: {
        value: "id",
        label: "label",
        emitPath: false,
      },
      dialogVisible: false,
    };
  },
  created() {
    this.getTrainTypeArrData();
  },
  methods: {
    //获取培训类型数据
    getTrainTypeArrData() {
      this.$post("/sys/category/train-type/enterprise", {}).then((ret) => {
        this.TrainTypeList = ret.data;
      });
    },
    //弹窗显示
    showPopUp() {
      this.dialogVisible = true;
      this.getData(-1);
    },
    //获取初始化数据
    getData(pageNum = 1) {
      const params = {
        pageNum: pageNum,
        pageSize: this.pageSize,
      };
      if (this.searchData.kpointName) {
        params.kpointName = this.searchData.kpointName;
      }
      if (this.searchData.trainTypeId) {
        params.trainTypeId = this.searchData.trainTypeId;
      }
      if (this.searchData.uploadTime) {
        params.startDate = this.searchData.uploadTime[0];
        params.endDate = this.searchData.uploadTime[1];
      }
      this.radio = "";
      this.doFetch({
        url: "/biz/et/courseware/page",
        params,
        pageNum,
      });
    },
    //获取高度
    getTableHeight(opDom = true, page = true) {
      let tHeight = 740 - (3 + 1.35 + 3.25 + 0.675 + 2 + 1.35) * 16;
      if (opDom) {
        tHeight -= 40 + 0.675 * 16 + 1;
      }
      if (page) {
        tHeight -= 32;
      }
      this.tableHeight = tHeight;
    },
    doOk() {
      const { radio } = this;
      if (radio) {
        this.$emit("eventBus", radio);
        this.dialogVisible = false;
      } else {
        this.$message({
          message: "请选择要关联的课件",
          type: "warning",
        });
      }
    },
  
    //关闭弹窗
    doClose() {
      this.dialogVisible = false;
      this.radio = ''
    },
    getTime(val) {
      if (!val) {
        return "0秒";
      }
      let h = parseInt(val / 60 / 60);
      let m = parseInt((val % 3600) / 60);
      let s = val % 60;
      if (h < 9) {
        h = "0" + h;
      }
      if (m < 9) {
        m = "0" + m;
      }
      if (s < 9) {
        s = "0" + s;
      }
      return (
        (h != "00" ? h + "时" : "") +
          (m != "00" ? m + "分" : "") +
          (s != "00" ? s + "秒" : "") || ""
      );
    },
  },
};
</script>
<style lang="less" scoped>
.CourseTablekponit {
  /deep/.el-radio {
    .el-radio__label {
      display: none;
    }
    .el-radio__inner{
      width: 18px;
      height: 18px;
      border-color: #777;
    }
    .el-radio__inner:hover{
      border-color: #5c6be8;
    }
    .el-radio__input.is-checked .el-radio__inner{
      border-color: #5c6be8;
      background-color: #5c6be8;
    }
  }
}
</style>
