<template>
  <el-dialog
    :visible.sync="dialogVisible"
    top="2%"
    width="90%"
    title="批量上传课件"
    :center="true"
    @close="doClose"
  >
    <section class="CourseTables">
      <div class="operationControl" style="border: 0">
        <div class="searchbox">
          <div title="课件名称" class="searchboxItem">
            <span class="itemLabel">课件名称:</span>
            <el-input
              clearable
              v-model="searchData.kpointName"
              type="text"
              size="small"
              placeholder="请输入课件名称"
            />
          </div>
          <div title="培训类型" class="searchboxItem">
            <span class="itemLabel">培训类型:</span>
            <el-cascader
              v-model="searchData.trainTypeId"
              :options="TrainTypeList"
              filterable
              :show-all-levels="false"
              :props="{
                emitPath: false,
                value: 'id',
                label: 'label',
                children: 'children',
                checkStrictly: true,
              }"
              size="small"
            ></el-cascader>
          </div>
          <div title="上传时间" class="searchboxItem">
            <span class="itemLabel">上传时间:</span>
            <el-date-picker
              v-model="searchData.uploadTime"
              type="daterange"
              range-separator="至"
              value-format="timestamp"
              start-placeholder="开始日期"
              end-placeholder="结束日期"
            ></el-date-picker>
          </div>
          <div>
            <el-button class="bgc-bv" @click="getData()">查询</el-button>
          </div>
        </div>
      </div>
      <div class="framePage-scroll">
        <div class="ovy-a">
          <el-table
            ref="multipleTable"
            :data="tableData"
            :height="tableHeight"
            size="small"
            tooltip-effect="dark"
            row-key="kpointId"
            style="width: 100%"
            @selection-change="change"
            stripe
            border
          >
            <el-table-column
              type="selection"
              width="50px"
              align="center"
              :reserve-selection="true"
            >
            </el-table-column>
            <el-table-column
              label="课件名称"
              align="center"
              show-overflow-tooltip
              prop="kpointName"
              width="200"
            />
            <el-table-column label="培训类型" align="left" prop="categoryName">
              <template slot-scope="scope">
                {{ scope.row.categoryName.split("##")[0] }}
              </template>
            </el-table-column>
            <el-table-column
              label="时长"
              align="center"
              show-overflow-tooltip
              prop="kpointDuration"
            >
              <template slot-scope="scope">{{
                getTime(scope.row.kpointDuration) || "--"
              }}</template>
            </el-table-column>
            <el-table-column label="创建时间" align="left" prop="createTime">
              <template slot-scope="scope">
                {{ scope.row.createTime }}
              </template>
            </el-table-column>
            <Empty slot="empty" />
          </el-table>
        </div>
      </div>
      <PageNum :apiData="apiData" @sizeChange="sizeChange" @getData="getData" />
      <div class="Batch">
        <div class="BatchBox">
          <div class="BatchBoxFirst">
            <p>上传课件</p>
            <p>（{{ backArr.length }}）</p>
          </div>
          <div class="BatchBoxSecond ovy-a">
            <div class="BatchforBox">
              <div
                v-for="(item, index) in backArr"
                :key="index"
                class="BatchforFlex"
              >
                <div class="BatchImgBox" @click="deleteImg(item)">
                  <img
                    :src="require('@/assets/close_.png')"
                    alt=""
                    @mouseover="transToWhite(index, $event)"
                    @mouseleave="transToBlack(index, $event)"
                  />
                </div>
                <span style="padding-left: 5px">{{ item.kpointName }}</span>
              </div>
            </div>
          </div>
        </div>
      </div>
      <span class="dialog-footer" style="margin-top: 10px">
        <div>
          <el-button class="bgc-bv" @click="doClose">取消</el-button>
          <el-button class="bgc-bv" @click="doOk">确定</el-button>
        </div>
      </span>
    </section>
  </el-dialog>
</template>
<script>
import Empty from "@/components/Empty.vue";
import PageNum from "@/components/PageNum.vue";
import List from "@/mixins/List";
import { mapGetters } from "vuex";
export default {
  name: "courseMoreTable",
  components: {
    Empty,
    PageNum,
  },
  mixins: [List],
  data() {
    return {
      //搜索条件
      searchData: {
        kpointName: "", //课件名称
        trainTypeId: "", //培训类型
        uploadTime: "", //上传时间
      },
      multipleTable: [],
      props: {
        value: "id",
        label: "label",
        emitPath: false,
      },
      dialogVisible: false,
      start: false,
      backArr: [],
    };
  },
  created() {
    this.getTrainTypeArrData()
  },
  updated() {
    setTimeout(() => {
      this.tableData.forEach((item, index) => {
        this.$refs.multipleTable.toggleRowSelection(
          item,
          this.backArr.some((one) => item.kpointId == one.kpointId)
        );
      });
    }, 200);
  },
  methods: {
    /* 鼠标移入移出 */
    transToWhite(index, e) {
      e.target.src = require("@/assets/error.png");
    },
    transToBlack(index, e) {
      e.target.src = require("@/assets/close_.png");
    },
  //获取培训类型数据
    getTrainTypeArrData() {
      this.$post("/sys/category/train-type/enterprise", {}).then((ret) => {
        this.TrainTypeList = ret.data;
      });
    },

    change(rowArr) {
      this.backArr = rowArr;
      this.multipleTable = rowArr;
    },
    showPopUp() {
      this.dialogVisible = true;
      this.start = true;
      if (this.start) {
        this.getData();
      }
    },
    //获取列表数据
    getData(pageNum = 1) {
      const params = {
        pageNum: pageNum,
        pageSize: this.pageSize,
      };
      if (this.searchData.kpointName) {
        params.kpointName = this.searchData.kpointName;
      }
      if (this.searchData.trainTypeId) {
        params.trainTypeId = this.searchData.trainTypeId;
      }
      if (this.searchData.uploadTime) {
        params.startDate = this.searchData.uploadTime[0];
        params.endDate = this.searchData.uploadTime[1];
      }
      if (this.start) {
        this.doFetch({
          url: "/biz/et/courseware/page",
          params,
          pageNum,
        });
      }
    },
    //删除选择课件
    deleteImg(itemobj) {
      var newArr = this.backArr.filter(
        (item) => item.kpointId != itemobj.kpointId
      );
      this.backArr = newArr;
      this.multipleTable.find((el) => {
        if (el.kpointId == itemobj.kpointId) {
          this.$refs.multipleTable.toggleRowSelection(el, false);
        }
      });
    },
    //获取列表高度
    getTableHeight(opDom = true, page = true) {
      let tHeight = 760 - (3 + 1.35 + 3.25 + 0.675 + 2 + 1.35 + 1) * 16;
      if (opDom) {
        tHeight -= 40 + 0.675 * 16 + 1;
      }
      if (page) {
        tHeight -= 32;
      }
      this.tableHeight = tHeight;
    },
    //选完课件确认事件
    doOk() {
      const { backArr } = this;
      if (backArr.length) {
        this.$emit("eventBus", backArr);
        this.dialogVisible = false;
        this.backArr = [];
        this.$refs.multipleTable.clearSelection();
      } else {
        this.$message({
          message: "请选择要关联的课件",
          type: "warning",
        });
      }
    },
    //关闭弹窗
    doClose() {
      this.dialogVisible = false;
      this.backArr = [];
      this.$refs.multipleTable.clearSelection();
    },
    //时间转换
    getTime(val) {
      if (!val) {
        return "0秒";
      }
      let h = parseInt(val / 60 / 60);
      let m = parseInt((val % 3600) / 60);
      let s = val % 60;
      if (h < 9) {
        h = "0" + h;
      }
      if (m < 9) {
        m = "0" + m;
      }
      if (s < 9) {
        s = "0" + s;
      }
      return (
        (h != "00" ? h + "时" : "") +
          (m != "00" ? m + "分" : "") +
          (s != "00" ? s + "秒" : "") || ""
      );
    },
  },
};
</script>
<style lang="less">
.CourseTables {
  .el-radio {
    .el-radio__label {
      display: none;
    }
    .el-radio__inner {
      width: 18px;
      height: 18px;
      border-color: #777;
    }
    .el-radio__inner:hover {
      border-color: #5c6be8;
    }
    .el-radio__input.is-checked .el-radio__inner {
      border-color: #5c6be8;
      background-color: #5c6be8;
    }
  }
  .Batch {
    padding: 1rem 1rem;
    .BatchBox {
      display: flex;
      height: 120px;
      border: 1px solid #ebeef5;
      border-radius: 5px;
      .BatchBoxFirst {
        width: 260px;
        background: #f2f7fd;
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
      }
      .BatchBoxSecond {
        width: 100%;
        .BatchforBox {
          display: flex;
          flex-wrap: wrap;
          .BatchforFlex {
            display: flex;
            align-items: center;
            padding: 5px 20px;
            cursor: pointer;
            .BatchImgBox {
              display: flex;
              justify-content: center;
              align-items: center;
            }
            img {
              width: 16px;
              height: 16px;
            }
          }
        }
      }
    }
  }
}
</style>
